import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-nav-card',
  templateUrl: './nav-card.component.html',
  styleUrls: ['./nav-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardComponent {
  constructor() {}
}
