<div class="flex flex-col sm:flex-row sm:flex-wrap">
  <div
    class="flex flex-1"
    [class.sm:items-center]="centerHeader"
    [ngClass]="
      helpLink && helpText
        ? 'items-center sm:flex-col sm:items-start'
        : 'flex-col items-start'
    "
  >
    <h3
      [ngClass]="h1 ? 'heading1' : h2 ? 'heading2-new' : 'heading-new'"
      class="text-left"
    >
      <ng-content></ng-content>
    </h3>
    <ng-content select="[slot=subtitle]"></ng-content>
    @if (helpLink && helpText) {
      <div class="ml-4 sm:ml-0 sm:mt-1">
        <mspot-help-link [link]="helpLink">{{ helpText }}</mspot-help-link>
      </div>
    }
  </div>

  <div
    class="flex flex-wrap items-center justify-start sm:justify-end"
    [ngClass]="link || add.observers.length > 0 ? 'mt-4 sm:mt-0' : ''"
  >
    <ng-content select="[slot=end]"></ng-content>

    @if (link) {
      <a
        data-cy="headBtn__link"
        class="btn--icon btn-primary w-full sm:w-auto"
        [routerLink]="link"
      >
        <ng-icon name="hero-plus-circle" class="-ml-1 mr-1.5 text-xl"></ng-icon>
        {{ text }}
      </a>
    }
    @if (!link && add.observers.length > 0 && addEnabled) {
      <button
        class="btn--icon btn-primary w-full sm:w-auto"
        (click)="add.emit()"
        [disabled]="disableBtn"
        data-cy="addBtn"
      >
        <ng-icon name="hero-plus-circle" class="-ml-1 mr-1.5 text-xl"></ng-icon>
        {{ text }}
      </button>
    }

    <ng-content select="[slot=last]"></ng-content>
  </div>
</div>
