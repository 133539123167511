import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
@Pipe({
  name: 'fsDateTime',
})
export class FsDateTimePipe extends DatePipe implements PipeTransform {
  override transform(value: any | Timestamp | Date, ...args: any[]): any {
    if (value && !(value instanceof Date) && value.toDate) {
      return super.transform(value.toDate(), 'short');
    } else if (typeof value === 'number' && value > 0) {
      return super.transform(value, 'short');
    } else if (typeof value === 'string') {
      return super.transform(value, 'short');
    } else if (isFirestoreTimestamp(value)) {
      return super.transform(
        new Timestamp(value._seconds, value._nanoseconds).toDate(),
        'short',
      );
    } else {
      return '';
    }
  }
}

const isFirestoreTimestamp = (value: any): boolean => {
  if (!value) {
    return false;
  }

  if ('_seconds' in value && '_nanoseconds' in value) {
    return true;
  }

  return false;
};
